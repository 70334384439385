button {
    padding: 10px 20px;
    background: #189f77;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
}

.navbar1 {
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #d5d5d5;
}

.navbar1 .logo {
    color: #111;
}

.quiz-container {
    position: relative;
    margin: 29px auto 20px;
    width: 95%;
    max-width: 999px;
    min-height: 370px;
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05);
}

.join-screen {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    padding: 20px;
}

.join-screen h2 {
    font-size: 25px;
    color: #111;
}

.join-screen p {
    max-width: 350px;
    color: #555;
}

.quiz-screen .question .progressbar {
    width: 0%;
    height: 5px;
}

.quiz-screen .question .progressbar .active {
    animation: progresss 10s linear forwards;
}

.quiz-screen .question .question-count {
    margin: 20px 119px;
    text-align: center;
    font-size: 15px;
    color: #555;
}

.quiz-screen .question .main {
    padding: 20px 40px;
}

.quiz-screen .question .main .title span {
    font-size: 15px;
    color: #555;
    font-weight: 600;
}

.quiz-screen .question .main .title p {
    margin-top: 5px;
    color: #111;
}

.quiz-screen .question .main .options {
    margin: 40px 0px 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.quiz-screen .question .main .options .option {
    width: calc(50% - 20px);
    border: 1px solid #bbb;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.quiz-screen .question .main .options .option.active {
    background: #1cbb8c;
    color: #fff;
}

.quiz-screen .question .control {
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #eee;
}

.result-screen {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.timer {
    float: right;
    color: red;
    font-weight: 600;
}
.ActionButton{
    padding: 9px;
    margin: 5px;
    background: #1cbb8c;
    border-radius: 5px;
    color: white;
    font-size: 15px;
}

.questionNumber{
    position: relative;
    left: 400px;
    font-size: 15px;
    font-weight: 600;
    color: red;
}
.optionsImage{
    height: 100px;
    width: auto;
    margin-left: 210px;
}



@keyframes progresss {
    0% {
        width: 0%;
        background: #555;
    }
    79% {
        width: 79%;
        background: #555;
    }
    80% {
        width: 80%;
        background: orangered;
    }
    100% {
        width: 100%;
        background: red;
    }
}